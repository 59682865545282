import { Input, Select } from "antd";
import { useUserContext } from "../context/UserContext";

export default function DeviceIdInput({
  deviceId,
  setDeviceId,
  isInventory = false,
}) {
  const { deviceIds, inventoryDeviceIds } = useUserContext();
  return (
    <div className="flex gap-3 justify-center items-center">
      <span className="whitespace-nowrap">Device ID: </span>
      {isInventory ? (
        <Input
          value={deviceId}
          onChange={(e) => {
            if (e.target.value.length > 12) return;
            setDeviceId(e.target.value);
          }}
          size="large"
          placeholder="Enter Device ID"
        />
      ) : (
        <Select
          showSearch
          placeholder="Select Device ID"
          onChange={setDeviceId}
          options={(isInventory ? inventoryDeviceIds : deviceIds).map((d) => ({
            value: d,
            label: d,
          }))}
          filterOption={(input, option) =>
            (option.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          size="large"
        />
      )}
    </div>
  );
}
