import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { getTemplate } from "../components/testing/Templates";

const scenarios = {
  default_on: [],
  emergency: [
    "pr1_check",
    "pr2_check",
    "fs1_check",
    "fs2_check",
    "fs3_check",
    "tds1_check",
    "tds2_check",
    "tr_check",
    "leakage_check",
  ],
  master: ["sed_flush", "carb_flush", "ro_flush", "alk_flush"],
  check_request: [
    "sed_flush",
    "carb_flush",
    "ro_flush",
    "alk_flush",
    "all_flush",
  ],
  reset_wifi: [],
  termination: [
    "sed_flush",
    "carb_flush",
    "ro_flush",
    "alk_flush",
    "master_program",
  ],
  dnd: [],
  reboot: [],
  pulse_calibration: ["fs1", "fs2", "fs3"],
};

export const UserContext = createContext({
  isTesting: false,
  scenarios: scenarios,
  deviceId: "F02880CD31E8",
  setDeviceId: () => {},
  deviceIds: [],
  inventoryDeviceIds: [],
  scenario: "default_on",
  setScenario: () => {},
  subscenario: null,
  setSubscenario: () => {},
  login: () => {},
  loggedIn: false,
  token: null,
  flushDuration: -1,
  setFlushDuration: () => {},
  flushType: "check",
  setFlushType: () => {},
  checksData: {},
  csvData: {},
  abortData: null,
  checkingProgress: true,
  startTesting: () => {},
  stopTesting: () => {},
  manualConfirm: () => {},
  fixMinMax: () => {},
});

export const useUserContext = () => {
  return useContext(UserContext);
};

export default function UserContextProvider({ children }) {
  const [isTesting, setIsTesting] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deviceId, setDeviceId] = useState("F02880CD31E8");
  const [deviceIds, setDeviceIds] = useState([]);
  const [inventoryDeviceIds, setInventoryDeviceIds] = useState([]);
  const [token, setToken] = useState();
  const [scenario, setScenario] = useState("default_on");
  const [subscenario, setSubscenario] = useState(scenarios["default_on"][0]);
  const [flushDuration, setFlushDuration] = useState(-1);
  const [flushType, setFlushType] = useState("check");
  const [checksData, setChecksData] = useState({});
  const [csvData, setCsvData] = useState({});
  const [abortData, setAbortData] = useState(null);
  const [checkingProgress, setCheckingProgress] = useState(false);
  const [socket, setSocket] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("phone")) {
      setToken(localStorage.getItem("token"));
      setLoggedIn(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!token) return;
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/deviceconfig/get-all-devices`,
          { headers: { Authorization: `Bearer ${token}` } },
        );
        if (res.status !== 200) throw new Error(res.data.message);
        setDeviceIds(res.data.map((device) => device.device_id));
      } catch (e) {
        console.error(e);
      }
    })();
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/inventory/get-all-products`,
          { headers: { Authorization: `Bearer ${token}` } },
        );
        if (res.status !== 200) throw new Error(res.data.message);
        setInventoryDeviceIds(
          res.data.data.data.map((device) => device.device_id),
        );
      } catch (e) {
        console.error(e);
      }
    })();
  }, [token]);

  useEffect(() => {
    if (!loading && location.pathname !== "/login" && !loggedIn) {
      navigate(`/login?to=${location.pathname}`);
    }
  }, [location.pathname, loading]);

  async function login(token, role, phone) {
    localStorage.setItem("token", token);
    localStorage.setItem("role", role);
    localStorage.setItem("phone", phone);
    setLoggedIn(true);
    setToken(token);
  }

  function disconnectSocket() {
    if (socket) {
      socket.off("message", onMessage);
      socket.off("connect");
      socket.off("disconnect");
      socket.off("error");
      setSocket();
    }
  }

  function onMessage(response) {
    try {
      if (typeof response === "string") response = JSON.parse(response);
      if (response && response.name) {
        if (response.name === "abort" || response.name === "completed") {
          if (response.name === "abort") setAbortData(response);
          setChecksData((prev) => {
            Object.keys(prev).forEach((key) => {
              if (prev[key].status !== "completed")
                prev[key].status = "aborted";
            });
            return { ...prev };
          });
          return;
        } else {
          setChecksData((prev) => {
            return {
              ...prev,
              [response.name]: response,
            };
          });
          setCsvData((prev) => {
            return {
              ...prev,
              [response.name]: prev[response.name]
                ? [...prev[response.name], response]
                : [response],
            };
          });
        }
      } else if (response && response.type === "update") {
        if (response.update === "reconnect") {
          setScenario(response.scenario);
          setSubscenario(response.subscenario);
          let csv = {};
          // data = {check, status, last_run, duration, comments, result, timestamp}
          response.checksData.forEach((c) => {
            if (!csv[c.check]) csv[c.check] = [];
            csv[c.check].push({
              name: c.check,
              status: c.status,
              lastRun: c.last_run,
              duration: c.duration,
              comments: c.comments,
              result: c.result,
            });
          });

          let cd = {};
          const template = getTemplate(response.scenario, response.subscenario);
          Object.keys(csv).forEach((c) => {
            cd[c] = csv[c][csv[c].length - 1];
            delete template[c];
          });
          cd = { ...cd, ...template };
          setChecksData(cd);
          setCsvData(csv);
          toast.success(
            "Testing on the device is already running. Reconnected to the session",
          );
        }
      }
    } catch (err) {
      toast(response);
    }
  }

  async function startTesting(
    deviceId,
    scenario,
    subscenario,
    datasetLocation,
  ) {
    const sock = io(process.env.REACT_APP_WS_URL);
    try {
      await new Promise((resolve, reject) => {
        sock.on("connect", () => {
          toast.success("Session connected to the backend");
          resolve();
        });
        sock.on("connect_error", (err) => {
          toast.error(err.message);
          reject(err.message);
        });
      });
      sock.on("error", (err) => {
        toast.error(err);
      });
      sock.on("disconnect", (reason) => {
        toast.error(reason);
      });
      sock.on("message", (data) => {
        onMessage(data);
      });
    } catch (err) {
      toast.error("Session not connected to the backend");
      return;
    }
    setSocket(sock);
    if (!token || !sock || !sock.connected) {
      toast.error("Session not connected to the backend");
      return;
    }
    setDeviceId(deviceId);
    setScenario(scenario);
    setSubscenario(subscenario);
    setChecksData(getTemplate(scenario, subscenario));
    setCheckingProgress(true);
    setCsvData({});
    setAbortData();

    sock.send(
      JSON.stringify({
        type: "start-testing",
        deviceId,
        scenario,
        subscenario,
        datasetLocation,
        flushDuration,
        flushType,
        token,
      }),
    );
    setIsTesting(true);
  }

  function stopTesting() {
    if (!token || !socket || !socket.connected) {
      toast.error("Session not connected to the backend");
      return;
    }
    socket.send(
      JSON.stringify({
        deviceId: deviceId,
        type: "stop-testing",
        token,
      }),
    );
    setIsTesting(false);
    setCheckingProgress(false);
    disconnectSocket();
  }

  function manualConfirm(name) {
    if (!socket) {
      toast.error("Websocket not connected. Refresh the page and try again");
      return;
    }
    confirmAlert({
      title: (checksData[name].comments ?? name) + " ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            socket.send(
              JSON.stringify({
                deviceId: deviceId,
                type: "update",
                scenario: scenario,
                action: name,
                value: true,
              }),
            );
            checksData[name].input.clicked = true;
            setChecksData(checksData);
          },
        },
        {
          label: "No",
          onClick: () => {
            let issue;
            if (name.match(/.*wifi.*/i))
              issue = prompt("Did you find any issue ?");
            socket.send(
              JSON.stringify({
                deviceId: deviceId,
                type: "update",
                scenario: scenario,
                action: name,
                value: false,
                comments: issue || "",
              }),
            );
            checksData[name].input.clicked = true;
            setChecksData(checksData);
          },
        },
      ],
      overlayClassName: "overlay",
    });
  }

  function fixMinMax() {
    if (!socket) {
      toast.error("Websocket not connected. Refresh the page and try again");
      return;
    }
    socket.send(
      JSON.stringify({
        deviceId: deviceId,
        type: "fix-emergency",
      }),
    );
    toast.success("Fixing min-max values...");
  }

  useEffect(() => {
    if (abortData) setCheckingProgress(false);
    else
      setCheckingProgress(
        !Object.values(checksData).every((c) => c.status === "completed"),
      );
  }, [checksData, abortData]);

  return (
    <UserContext.Provider
      value={{
        scenarios,
        isTesting,
        deviceId,
        setDeviceId,
        deviceIds,
        inventoryDeviceIds,
        scenario,
        setScenario,
        subscenario,
        setSubscenario,
        login,
        loggedIn,
        token,
        flushDuration,
        setFlushDuration,
        flushType,
        setFlushType,
        checksData,
        csvData,
        abortData,
        checkingProgress,
        startTesting,
        stopTesting,
        manualConfirm,
        fixMinMax,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
