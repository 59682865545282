import React, { useState } from "react";
import { useDebugContext } from "../context/DebugContext";
import LogHistoryModal from "./device/LogHistoryModal";
import DeviceIdInput from "./DeviceIdInput";

export default function Debug() {
  const {
    debugDeviceId,
    setDebugDeviceId,
    debugLogs,
    abortData,
    isDebugging,
    startDebug,
    stopDebug,
  } = useDebugContext();
  const [showLogHistory, setShowLogHistory] = useState(false);

  function startDebugging() {
    startDebug();
  }
  function stopDebugging() {
    stopDebug();
  }

  return (
    <div className="px-10 pt-20 w-full">
      <div className="flex relative gap-3 justify-center items-center w-full">
        <DeviceIdInput
          deviceId={debugDeviceId}
          setDeviceId={setDebugDeviceId}
        />
        <button
          onClick={isDebugging ? stopDebugging : startDebugging}
          className={`py-2 px-4 ml-5 font-bold text-white ${isDebugging ? "bg-red-500" : "bg-indigo-500"} rounded hover:bg-indigo-700 disabled:bg-gray-500 transition`}
        >
          {isDebugging ? "Stop Debugging" : "Start Debugging"}
        </button>
        <button
          onClick={() => setShowLogHistory(true)}
          className="py-2 px-4 ml-5 font-semibold text-indigo-500 rounded border-2 border-indigo-500 transition hover:text-white hover:bg-indigo-500 disabled:bg-gray-500"
        >
          View Older Logs
        </button>
      </div>
      {abortData && (
        <div className="mt-5 w-full text-xl font-semibold text-red-500">
          Aborted: <span className="ml-2">{abortData.reason}</span>
        </div>
      )}
      <div className="px-16 pt-5 pb-16 w-full">
        {debugLogs.map((log, i) => (
          <div key={i} className="flex gap-8 p-3 mt-2 w-full text-xl">
            <div className="text-sm">
              {new Date(log.timestamp)
                .toLocaleString("en-GB")
                .split(",")
                .map((t) => (
                  <div key={t}>{t}</div>
                ))}
            </div>
            <div
              className="font-mono text-base whitespace-pre-wrap grow"
              dangerouslySetInnerHTML={{
                __html: String.raw`${log.debug_response
                  ?.replaceAll("\\\\", "\\")
                  .replaceAll("\\n", "<br />")
                  .replaceAll("\\t", "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")
                  .replaceAll("\\r", "")}`,
              }}
            ></div>
          </div>
        ))}
      </div>
      <LogHistoryModal
        visible={showLogHistory}
        onClose={() => setShowLogHistory(false)}
      />
    </div>
  );
}
